<template>
    <v-data-table :headers="headers"
                  dense
                  disable-pagination
                  hide-default-footer
                  :items="items"
                  sort-by="id"
                  :search="search"
                  class="elevation-1"
                  @click:row="editItem">
        <template v-slot:top>
            <v-toolbar flat color="yellow">
                <v-toolbar-title>Notificaciones</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <!--<v-dialog v-model="propDialog" max-width="500px" persistent>
                    <template v-slot:activator>
                        <v-btn class="mx-2"
                               fab
                               dark
                               x-small
                               color="warning"
                               v-on:click="editItem(null)">
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <AsuntoMensajeAdjuntoDialogo></AsuntoMensajeAdjuntoDialogo>
                </v-dialog>-->
            </v-toolbar>
        </template>

        <template v-slot:item.soporte>
            <v-icon small>
                mdi-email
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="yellow" @click="initialize">Reset</v-btn>
        </template>
    </v-data-table>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    import AsuntoMensajeAdjuntoDialogo from '@/components/NE/AsuntoMensajeAdjuntoDialogo';

    export default {
        name: 'notificacion',
        data() {
            return {
                search: '',
                formTitle: '',
                headers: [
                    { text: 'Id', align: 'right', sortable: true, value: 'id', show: 'false' },
                    { text: 'Compañia', align: 'center', sortable: true, value: 'cliente' },
                    { text: 'Dominio', align: 'center', sortable: true, value: 'dominio' },
                    { text: 'Tramite', align: 'center', sortable: true, value: 'tramite' },
                    { text: 'Destino', align: 'center', sortable: true, value: 'destino' },
                    { text: 'soporte', align: 'center', value: 'soporte' },
                    { text: 'Dirección', align: 'center', sortable: true, value: 'direccionSoporte' },
                    { text: 'Asunto', align: 'left', sortable: true, value: 'asunto' },
                    { text: 'Estado', align: 'center', sortable: true, value: 'estado' },
                ]
            };
        },

        computed: {
            ...mapState('neNotificacion', ['dialog', 'items']),

            propDialog: {
                get() {
                    return this.dialog;
                },
                set(value) {
                    this.setDialog(value);
                }
            }
        },

        created() {
            this.initialize();
        },

        methods: {
            ...mapActions('neNotificacion', ['initialize', 'editItem', 'setDialog'])
        },
        components: {
            //AsuntoMensajeAdjuntoDialogo
        }
    };
</script>
