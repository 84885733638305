<template>
    <neNotificacionCrud></neNotificacionCrud>
</template>
<script>
    import neNotificacionCrud from '@/components/NE/NENotificacionCrud';

    export default {
        name: 'notificacion',
        data() {
            return {};
        },
        components: {
            neNotificacionCrud
        }
    };
</script>
